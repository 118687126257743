/* ResultPopup.css */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  backdrop-filter: blur(10px);
  z-index: 9999;
}

.popup-content {
  background-color: white;
  width: 80%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  color: black;
  overflow-y: auto;
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: 24px;
  color: #444;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10001;
  padding: 0;
}

.close-button:hover {
  color: #777; /* Slight color change on hover for feedback */
}

.popup-top {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70%;
}

.popup-image-section,
.popup-map-section {
  width: 50%;
  height: 100%;
  padding: 10px;
}

.popup-image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.popup-price {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
  text-align: left;
  padding: 0 20px;
}

.popup-name {
  font-size: 20px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 10px;
  color: #555;
  text-align: left;
  padding: 0 20px;
}

.popup-info {
  margin-top: 0px;
  text-align: left;
  padding: 0 20px; /* Add horizontal padding */
}

.popup-info p {
  margin: 5px 0;
  font-size: 19px;
  color: #333;
}

.popup-info strong {
  font-weight: 600;
}

/* Styling for the info sections */
.info-sections-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
}

.info-section {
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Drop shadow */
  text-align: center;
}

/* Make full-width sections */
.full-width {
  width: 100%;
  margin-top: 20px;
}

.info-section h4 {
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  color: #222;
  margin-bottom: 15px;
}

.info-item-popup {
  margin-top: 15px;
  font-size: 18px;
}

.info-item-popup strong {
  font-weight: 600;
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
  color: #222;
}

.info-item-popup p {
  font-size: 18px;
  color: #555;
  margin: 0;
}

/* Utility toggle */
.utility-toggle {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.9);
  padding: 5px;
  border-radius: 5px;
  z-index: 10002;
  display: flex;
  align-items: center;
  margin: 10px;
}

.utility-toggle span {
  margin-right: 8px;
}

.popup-map-section {
  position: relative;
  display: flex;
  flex-direction: column;
}

.restricted-info-item-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.lock-icon {
  margin-right: 10px;
}

.info-title {
  font-size: 115px;
  font-weight: bold;
  text-align: center;
}

.pay-to-unlock-message {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 20px;
  cursor: pointer;
  text-decoration: underline;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .popup-top {
    flex-direction: column;
    height: auto;
  }

  .popup-image-section,
  .popup-map-section {
    width: 100%;
    height: 300px;
  }

  .info-sections-row {
    flex-direction: column;
  }

  .info-section {
    width: 100%;
  }
}

.legend {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.9);
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 0.9rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  z-index: 10000;
}

.legend p {
  margin: 4px 0;
  display: flex;
  align-items: center;
}

.legend-color {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 2px;
}

.legend-water {
  background-color: blue;
}

.legend-sewer {
  background-color: green;
}

.toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.toggle-item {
  display: flex;
  align-items: center;
}

.toggle-item span {
  margin-right: 5px;
}

/* Map container should occupy full available space */
.map-container {
  width: 100%;
  height: 100%;
  position: relative;
}

/* Ensure parent containers also have defined heights */
.popup-map-section {
  height: 100%;
}
