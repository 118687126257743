.search-results-container {
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.search-bar-container {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #FFFFFF;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.content-container {
  overflow: hidden;
  display: flex;
  flex: 1;
  height: 100vh;
}

.map-container {
  width: 50%;
  height: 81.5vh;
  overflow: hidden;
  position: fixed;
}

.parcels-container {
  width: 50%;
  padding-right: 25px;
  padding-left: 25px;
  margin-left: 50%;
  background-color: #f5f5f5;
  overflow-y: auto;
}

.parcels-container ul {
  list-style-type: none;
  padding: 0;
}

.parcels-container li {
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add smooth transition */
  cursor: pointer; /* Indicate it's clickable */
}

.parcels-container li:hover {
  transform: scale(1.05); /* Slightly increase size on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a more pronounced shadow on hover */
}

.parcels-container li:active {
  transform: scale(0.98); /* Slight shrink effect on click */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Return shadow to normal */
}

.place-info {
  background-color: #e0f7fa;
  padding: 15px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add smooth transition for place-info */
}

.place-info:hover {
  transform: scale(1.05); /* Slightly increase size on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a more pronounced shadow on hover */
}

.place-info:active {
  transform: scale(0.98); /* Slight shrink effect on click */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Return shadow to normal */
}

.blur-background .search-bar-container,
.blur-background .content-container {
  filter: blur(8px);
  pointer-events: none;
}

.blur-background .search-bar-container,
.blur-background .content-container {
  display: none;
}

.toggle-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  gap: 10px;
  z-index: 1000;
}

.toggle-item {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  border-radius: 8px;
  margin-right: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.toggle-item span {
  margin-right: 8px;
}

/* Legend styling */
.legend-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: white;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  font-size: 14px;
  z-index: 10000;
}

.legend-item {
  display: flex;
  align-items: center;
  margin: 4px 0;
}

.legend-color {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
}

.legend-water {
  background-color: blue;
}

.legend-sewage {
  background-color: green;
}