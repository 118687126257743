@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Paytone+One&display=swap');

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
}

.landing-page {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    overflow-x: hidden;
    overflow-y: auto;
}

.overlay {
    background-image: url('./LandingPage.webp'); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100vw;
    height: 70vh; /* Keep the original height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    padding-left: 20vw;
    position: relative;
    color: white;
}

.overlay h1 {
    margin-bottom: 0px;
}

.overlay p {
    margin-bottom: 30px;
}

.search-bar {
    width: 40%;
    color: white;
}

.search-bar input,
.search-bar label {
    color: black;
}

.archivo-black-regular {
    font-family: "Archivo Black", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.section {
    width: 100%;
    padding: 60px 20px;
    border-top: 1px solid #ddd;
    background-color: white;
    text-align: center;
    color: black;
}

.section h2 {
    font-size: 24px;
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
}

.popular-searches .parcels-list {
    margin-right: 20px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    gap: 30px; /* Increased gap for more spacing */
}

.parcel-card {
    display: flex;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 400px;
    padding: 15px;
    align-items: center;
    transition: transform 0.2s ease-in-out; /* Add transition for smoothness */
    cursor: pointer; /* Change cursor to pointer on hover */
}

.parcel-card:hover {
    transform: scale(1.05); /* Grow slightly on hover */
}

.parcel-card:active {
    transform: scale(0.98); /* Slightly shrink on click */
}

.parcel-map {
    width: 10px;
    flex: 0 0 200px; /* Fixed width and height */
    width: 200px;
    height: 200px;
}

.parcel-text {
    margin-left: 20px;
    text-align: left;
    flex: 1; /* Allow text to grow and fill the remaining space */
}

.parcel-text h3 {
    margin: 0 0 10px;
}

.parcel-text p {
    margin: 5px 0;
}

.about-us p {
    font-size: 20px;
    font-family: "Montserrat", sans-serif;
    color: #333;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.about-us {
    height: 40vh;
    background-color: #EEEEEE;
    padding: 60px 20px;
    text-align: center;
    border-top: 1px solid #ddd;
}

.information-we-offer {
    background-color: white;
    text-align: center;
    padding-top: 60px;
}

.information-we-offer h2 {
    font-size: 24px;
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 equal-width columns */
    grid-gap: 20px; /* Add space between the boxes */
    justify-content: center;
    width: 70%; /* Adjust the overall width as needed */
    margin: 0 auto;
    align-items: center;
}

.info-item {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 10px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 5px;
    text-align: center;
    height: 100px; /* Make the boxes square */
}

.info-item svg {
    font-size: 40px;
}

.info-item p {
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    color: #333;
}

.info-row {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px auto;
  }
  
  .info-item {
    padding-top: 30px;
    margin-top: 15px;
    width: 140px; /* Same fixed width for all boxes */
    height: 90px; /* Same fixed height for all boxes */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 10px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .info-icon {
    font-size: 32px; /* or whatever size you prefer for the icon */
    margin-bottom: 8px;
  }
  