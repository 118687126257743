.signin-container {
  height: 50vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signin-button {
  margin-top: 20px !important;
  background-color: goldenrod !important;
  color: white !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2) !important;
}

.signin-button:hover {
  background-color: grey !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.4) !important;
}

.forgot-password {
  padding-top: 20px;
  color: #1976d2;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
}
